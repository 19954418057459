import React, { useState } from 'react'
import { Button, Grid } from '@material-ui/core'
import { verifyCodeSchema } from '../../schemas'
import { Field, Formik, Form } from 'formik'
import { TextField } from 'formik-material-ui'
import { useErrorNotification } from '../../../civic-champs-shared/api/hooks/useErrorNotification'
import useSuccessNotification from '../../../civic-champs-shared/api/hooks/useSuccessNotification'
import AnchorResendCode from '../anchor-resend-code'
import { Auth } from 'aws-amplify'
import { PALETTE_COLORS } from '../../../civic-champs-shared/constants/colors'
import { makeStyles } from '@material-ui/core/styles'
import getErrorMessage from '../../../civic-champs-shared/utils/getErrorMessage'

const waitFor = duration =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, duration)
  })

const useStyles = makeStyles(theme => ({
  resendCodeText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  centerCompleteSignUpText: {
    display: 'grid',
    textAlign: 'center',
    padding: '10px',
  },
  boldText: {
    fontWeight: 'bold',
    color: theme.palette.primary.dark,
  },
}))

function ConfirmSignUp(props) {
  const [error, setError] = useState('')
  const { onSubmit, signUpInfo, classes } = props
  const { email } = signUpInfo

  const [resendingDisabled, setResendingDisabled] = useState(false)
  const showSuccess = useSuccessNotification()
  const showError = useErrorNotification()

  const classesMUI = useStyles()

  const handleSubmitResendCode = async () => {
    setResendingDisabled(true)
    try {
      await Auth.resendSignUp(signUpInfo.username)
      showSuccess('Code resend successfully')
    } catch (err) {
      showError('Error sending code', err)
    } finally {
      await waitFor(5000)
      setResendingDisabled(false)
    }
  }

  return (
    <>
      <div className={classesMUI.centerCompleteSignUpText}>
        <div className="label-sign-up" style={{ color: PALETTE_COLORS.textColors.default }}>
          Complete Sign-Up
        </div>
        <div className="label-sign-up-instructions" style={{ color: PALETTE_COLORS.textColors.default }}>
          Please enter the verification code <br />
          we've sent to <span className={classesMUI.boldText}>{signUpInfo.email || signUpInfo.phone}</span>
        </div>
      </div>

      <Formik
        initialValues={{
          code: '',
        }}
        validationSchema={verifyCodeSchema}
        onSubmit={values => onSubmit(verifyCodeSchema.cast(values))}
        validateOnBlur
      >
        {({ dirty, isValid }) => (
          <Form>
            <Grid container spacing={2} direction="column" justify="center" alignItems="center">
              <Grid item container spacing={2} justify="center">
                <Grid item>
                  <Field
                    component={TextField}
                    name="code"
                    placeholder="Verification Code"
                    className={classes.input}
                    variant="outlined"
                    errorMessage={'Hubo un error machin'}
                  />
                </Grid>
              </Grid>
              <div className={classesMUI.resendCodeText}>
                Didn't receive the code?
                <AnchorResendCode
                  anchorText="Resend verification code"
                  onClick={handleSubmitResendCode}
                  disabled={resendingDisabled}
                />
              </div>
              <Grid item container spacing={2} justify="center">
                <Button variant="contained" disabled={!dirty || !isValid} type="submit" className="submit button">
                  Verify Account
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default ConfirmSignUp
