import React, { useState, useMemo, useCallback, useEffect } from 'react'
import Welcome from './Welcome'
import { get, upperFirst } from 'lodash'
import { RequirementsModal } from 'civic-champs-shared/question-sets/components'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { invitationTypeToActionLookup, InvitationType } from 'civic-champs-shared/question-sets/types'
import { Button } from '@material-ui/core'
import { useHistory } from 'react-router'
import { useAcceptOrRejectInvite, useUpdateProfile } from 'invite/hooks'

// TODO: confirm language https://civicchamps.atlassian.net/browse/CCA-1363
const useFirstSentence = invite => {
  return useMemo(() => {
    const { invitationType, organization, group, registration } = invite
    const orgName = (organization && organization.name) || 'Civic Champs'
    const eventName = registration && registration.name
    if (invitationType === InvitationType.VOLUNTEERING && eventName) {
      return `${orgName} has invited you to begin volunteering at the event '${eventName}'.  However, they need to first gather some more information from you.`
    } else if (invitationType === InvitationType.VOLUNTEERING) {
      return `${orgName} has invited you to begin volunteering.  However, they need to first gather some more information from you.`
    } else if (invitationType === InvitationType.MENTORSHIP) {
      return `${orgName} has invited you to begin mentoring.  However, they need to first gather some more information from you.`
    } else if (invitationType === InvitationType.CUSTOM_GROUP) {
      return `${orgName} has invited you to join the group '${group.name}'.  However, they need to first gather some more information from you.`
    } else if (invitationType === InvitationType.MEMBERSHIP) {
      return `${orgName} has invited you to become a member.  However, they need to first gather some more information from you.`
    } else {
      return `${orgName} has requested that please provide some additional information in order to continue using your Civic Champs account.`
    }
  }, [invite])
}

export default function InviteAlreadyAccepted({ invite }) {
  const [inProgress, setInProgress] = useState(true)
  const fulfillRequirementsPrompt = useShowPrompt(RequirementsModal)
  const firstSentence = useFirstSentence(invite)
  const history = useHistory()
  const [updateProfile] = useUpdateProfile(invite)
  const [acceptOrRejectInvite] = useAcceptOrRejectInvite(invite)
  const handleRequirements = useCallback(async () => {
    if (!invite) {
      return null
    }

    const { person, filters } = invite
    try {
      const done = await fulfillRequirementsPrompt({
        person: person,
        filters: filters || {
          personId: person.id,
          invitationToken: invite.secureToken,
          action: get(invitationTypeToActionLookup, invite.invitationType, 'user_sign_up'),
          freshAnswerSet: false,
          excludeCompletedAnswerSets: true,
          excludeCredentials: false,
        },
        isAdmin: false,
        continueEvenIfError: false,
      })
      if (done) {
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage({ type: 'onboarding-complete' })
        }
        history.push({
          pathname: '/all-set',
          state: { invite },
        })

        await acceptOrRejectInvite('accept')
        setInProgress(false)
      }
    } catch (error) {}
  }, [invite, fulfillRequirementsPrompt, history, acceptOrRejectInvite])

  useEffect(() => {
    async function verifyContacts() {
      await updateProfile(invite.person)
    }
    verifyContacts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (inProgress) {
    return (
      <div className="invite-header">
        <h2>Hi {upperFirst(invite.recipientGivenName)}</h2>
        <p style={{ textAlign: 'center' }}>
          {firstSentence} Please complete their short questionnaire(s) and we will get you connected to your account.
        </p>
        <Button className="submit button" onClick={handleRequirements}>
          Complete Questionnaires
        </Button>
      </div>
    )
  }

  return (
    <>
      <Welcome invite={invite}>
        <h1 style={{ textAlign: 'center' }}>
          You are all set! You have completed all required questionnaires and waivers.
        </h1>
      </Welcome>
    </>
  )
}
