import * as React from 'react'
import { Formik, FormikErrors, FormikProps } from 'formik'
import { Question, QuestionType, ConditionLookup, QuestionAnsweringFormProps } from '../../types'
import { initQuestionAnswers, mapQuestionToAnswerPayload } from '../../utils'
import './index.scss'
import * as yup from 'yup'
import { answerSetValidationSchema } from '../../schema'
import { Button } from '@material-ui/core'
import {
  RadioQuestion,
  CheckboxQuestion,
  SelectQuestion,
  InputQuestion,
  TextQuestion,
  TrueFalseQuestion,
  LocationQuestion,
  PhoneQuestion,
  EmailQuestion,
  ContactQuestion,
} from './questions'
import { InitialQuestionAnsweringValues } from './interface'

export const QuestionAnsweringForm = (props: QuestionAnsweringFormProps) => {
  const {
    onSubmit,
    onSkip,
    onCancel,
    onEdit,
    questionSet,
    children,
    additionalInitialValues = {},
    additionalSchema = yup.object().shape({}),
    required,
    heading,
    readonly,
  } = props

  const validationSchema = additionalSchema.concat(yup.object({ questionSet: answerSetValidationSchema }))

  const submit = (values: any) => {
    const payload = {
      ...additionalInitialValues,
      ...values,
      questionSet: { ...questionSet, questions: values.questionSet.questions.map(mapQuestionToAnswerPayload) },
    }
    onSubmit(payload)
  }

  const initialValues: InitialQuestionAnsweringValues = {
    ...additionalInitialValues,
    questionSet: { ...questionSet, questions: initQuestionAnswers(questionSet) },
  }

  return (
    <div style={{ width: '95%', overflow: 'auto' }}>
      {heading && (
        <h2>
          <b>{heading}</b>
        </h2>
      )}
      <div className="feedback-wrap">
        <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={submit}>
          {(props: FormikProps<InitialQuestionAnsweringValues>) => {
            const {
              values,
              errors,
              touched,
              dirty,
              isValid,
              handleSubmit,
              isSubmitting,
              setFieldTouched,
              setFieldValue,
            } = props

            const getFormikQuestionErrors = (index: number) =>
              errors?.questionSet?.questions?.[index] as FormikErrors<Question>

            const getFormikQuestionsTouched = (index: number) => touched?.questionSet?.questions?.[index]

            const renderErrors = (index: number) => getFormikQuestionsTouched(index) && getFormikQuestionErrors(index)

            const strategyToRenderQuestion = (question: Question, index: number): any => {
              switch (question.questionType) {
                case QuestionType.SINGLE_CHOICE:
                  return (
                    <RadioQuestion
                      key={index}
                      error={renderErrors(index)}
                      item={question}
                      onChange={handleChangeValue(index, 'response')}
                      onBlur={handleBlurValue(index)}
                      readonly={readonly}
                    />
                  )
                case QuestionType.MULTIPLE_CHOICE:
                  return (
                    <CheckboxQuestion
                      key={index}
                      error={renderErrors(index)}
                      item={question}
                      onChangeCheckbox={handleChangeValue(index, 'response')}
                      onChangeOtherAnswer={handleChangeValue(index, 'otherAnswer')}
                      onBlur={handleBlurValue(index)}
                      readonly={readonly}
                    />
                  )
                case QuestionType.DROPDOWN:
                  return (
                    <SelectQuestion
                      key={index}
                      error={renderErrors(index)}
                      item={question}
                      onChange={handleChangeValue(index, 'response')}
                      onBlur={handleBlurValue(index)}
                      readonly={readonly}
                    />
                  )
                case QuestionType.SHORT_TEXT:
                  return (
                    <InputQuestion
                      key={index}
                      error={renderErrors(index)}
                      item={question}
                      onChange={handleChangeValue(index, 'response')}
                      onBlur={handleBlurValue(index)}
                      readonly={readonly}
                    />
                  )
                case QuestionType.NUMBER:
                  return (
                    <InputQuestion
                      key={index}
                      error={renderErrors(index)}
                      item={question}
                      onChange={handleChangeValue(index, 'response')}
                      onBlur={handleBlurValue(index)}
                      readonly={readonly}
                    />
                  )
                case QuestionType.LONG_TEXT:
                  return (
                    <TextQuestion
                      key={index}
                      error={renderErrors(index)}
                      item={question}
                      onChange={handleChangeValue(index, 'response')}
                      onBlur={handleBlurValue(index)}
                      readonly={readonly}
                    />
                  )
                case QuestionType.TRUE_OR_FALSE:
                  return (
                    <TrueFalseQuestion
                      key={index}
                      error={renderErrors(index)}
                      item={question}
                      onChange={handleChangeValue(index, 'response')}
                      onBlur={handleBlurValue(index)}
                      readonly={readonly}
                    />
                  )
                case QuestionType.EMERGENCY_CONTACT:
                  return (
                    <ContactQuestion
                      key={index}
                      error={renderErrors(index)}
                      item={question}
                      fieldName={getFieldName(index, 'response')}
                      readonly={readonly}
                    />
                  )
                case QuestionType.HOME_ADDRESS:
                  return (
                    <LocationQuestion
                      key={index}
                      error={renderErrors(index)}
                      item={question}
                      fieldName={getFieldName(index, 'response')}
                      readonly={readonly}
                    />
                  )
                case QuestionType.PHONE_NUMBER:
                  return (
                    <PhoneQuestion
                      key={index}
                      error={renderErrors(index)}
                      item={question}
                      fieldName={getFieldName(index, 'response')}
                      readonly={readonly}
                    />
                  )
                case QuestionType.EMAIL:
                  return (
                    <EmailQuestion
                      key={index}
                      error={renderErrors(index)}
                      item={question}
                      fieldName={getFieldName(index, 'response')}
                      readonly={readonly}
                    />
                  )
                default:
                  return null
              }
            }

            const triggerAnswerByQuestionId: ConditionLookup = values.questionSet.questions.reduce(
              (acc: ConditionLookup, question: Question, targetedQuestionIndex: number) => {
                const { conditionedOnAnswer, conditionedOnQuestionId, questionId: targetedQuestionId } = question
                if (conditionedOnQuestionId) {
                  acc[conditionedOnQuestionId] = [
                    ...(acc[conditionedOnQuestionId] || []),
                    { conditionedOnAnswer, targetedQuestionId, targetedQuestionIndex },
                  ]
                }
                return acc
              },
              {},
            )

            const getFieldName = (idx: number, key?: keyof Question, subkey?: string): string =>
              `questionSet.questions[${idx}]${key ? `[${key}]` : ''}${subkey ? `[${subkey}]` : ''}`

            const handleChangeValue = (idx: number, key: keyof Question) => (value: any) => {
              if (key === 'response') {
                const { questionId } = values.questionSet.questions[idx]
                const triggerInfos = triggerAnswerByQuestionId[questionId]
                if (triggerInfos) {
                  triggerInfos.forEach(triggerInfo => {
                    const { conditionedOnAnswer, targetedQuestionIndex } = triggerInfo
                    setFieldValue(`${getFieldName(targetedQuestionIndex)}.disabled`, conditionedOnAnswer !== value)
                  })
                }
              }
              setFieldValue(getFieldName(idx, key), value)
            }

            const handleBlurValue = (idx: number) => () => {
              setFieldTouched(getFieldName(idx), true)
            }
            return (
              <form onSubmit={handleSubmit}>
                <div className="feedback-form-block">
                  <div className="feedback-form-block-question-list">
                    {children}
                    {values.questionSet.questions.map((question: Question, index: number) =>
                      strategyToRenderQuestion(question, index),
                    )}
                  </div>
                  <div className="feedback-form-block__submit">
                    {!required && onSkip && <Button onClick={() => onSkip(values)}>SKIP</Button>}
                    {questionSet?.answerSetId && !readonly && onCancel && <Button onClick={onCancel}>CANCEL</Button>}
                    {!readonly && (
                    <Button type="submit" disabled={(dirty && !isValid) || isSubmitting}>
                      SUBMIT
                    </Button>
                    )}
                    {questionSet?.answerSetId && readonly && onEdit && <Button onClick={onEdit}>Edit</Button>}
                  </div>
                </div>
              </form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}
