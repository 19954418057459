import yup from '../utils/yup'
import { ContactFormValue } from './components/question-set-answering/base/contact-form/types'
import { Question, QuestionSetResponse, QuestionType, OrgNamedRef } from './types'
import { OTHER_OPTION } from './utils'

const REQUIRED_FIELD = 'Required field'
const PHONE_VALIDATION_TEXT = 'Phone number must be a valid US phone number'

export const organizationSchema = yup.object<OrgNamedRef>({
  id: yup.number().required(),
  name: yup.string().required(),
  timeZone: yup.string(),
  website: yup.string(),
})

const answerWithCheckboxValidationSchema = yup.mixed().when('isRequired', {
  is: true,
  then: yup.array().ensure().of(yup.string()).min(1, 'Please choose at least one option').required(REQUIRED_FIELD),
  otherwise: yup.array().ensure().of(yup.string()).nullable().notRequired(),
})

const answerStringValidationSchema = yup.mixed().when('isRequired', {
  is: true,
  then: yup.string().trim().required(REQUIRED_FIELD),
  otherwise: yup.string().trim().nullable().notRequired(),
})

const answerNumberValidationSchema = yup.mixed().when('isRequired', {
  is: true,
  then: yup.number().min(0).required(REQUIRED_FIELD),
  otherwise: yup.number().nullable().notRequired(),
})

const answerTrueFalseValidationSchema = yup.mixed().when('isRequired', {
  is: true,
  then: yup.boolean().required(REQUIRED_FIELD),
  otherwise: yup.boolean().nullable().notRequired(),
})

const answerEmailValidationSchema = yup.mixed().when('isRequired', {
  is: true,
  then: yup.string().required(REQUIRED_FIELD).email('Invalid email'),
  otherwise: yup.string().nullable().notRequired(),
})

// @ts-ignore
const phoneValidationSchema = yup.string().phoneNumber(PHONE_VALIDATION_TEXT).nullable()

const answerPhoneValidationSchema = yup.mixed().when('isRequired', {
  is: true,
  then: phoneValidationSchema,
  otherwise: yup.string().nullable().notRequired(),
})

const phonesSchema = () =>
  yup.object({
    phoneNumber: phoneValidationSchema,
    homePhoneNumber: phoneValidationSchema,
    workPhoneNumber: phoneValidationSchema,
  })

const answerLocationValidationSchema = yup.mixed().when('isRequired', {
  is: true,
  then: yup.object({
    location: yup.object({
      address: yup.object({
        line1: yup.string().required(REQUIRED_FIELD),
        line2: yup.string().notRequired().nullable(),
        city: yup.string().required(REQUIRED_FIELD),
        state: yup.string().required(REQUIRED_FIELD),
        zip: yup.string().notRequired().nullable(),
        country: yup.string().required(REQUIRED_FIELD),
      }),
    }),
  }),
  otherwise: yup
    .object({
      location: yup.object({
        address: yup.object({
          line1: yup.string().notRequired(),
          line2: yup.string().notRequired().nullable(),
          city: yup.string().notRequired(),
          state: yup.string().notRequired(),
          zip: yup.string().notRequired().nullable(),
          country: yup.string().notRequired(),
        }),
      }),
    })
    .notRequired(),
})

const answerContactValidationSchema = yup.mixed<ContactFormValue>().when('isRequired', {
  is: true,
  then: yup
    .object({
      givenName: yup.string().required(REQUIRED_FIELD),
      familyName: yup.string().required(REQUIRED_FIELD),
      relationship: yup.string().required(REQUIRED_FIELD),
      email: yup.string().notRequired().nullable().email(),
    })
    .concat(
      // @ts-ignore
      phonesSchema().atLeastOneOf(
        ['phoneNumber', 'homePhoneNumber', 'workPhoneNumber'],
        'Requires at least one phone number',
      ),
    ),
  otherwise:
    // @ts-ignore
    yup
      .object({
        givenName: yup.string().notRequired(),
        familyName: yup.string().notRequired(),
        relationship: yup.string().notRequired(),
        email: yup.string().notRequired().nullable().email(),
      })
      .concat(
        // @ts-ignore
        phonesSchema(),
      )
      .test('allOrNone', 'Requires first name, last name, relationship, any phone or none', function (value) {
        const requiredFields = ['givenName', 'familyName', 'relationship']
        const oneOfFields = ['phoneNumber', 'homePhoneNumber', 'workPhoneNumber']

        return (
          (requiredFields.every(key => value[key]) && oneOfFields.some(key => value[key])) ||
          [...requiredFields, ...oneOfFields].every(key => !value[key])
        )
      }),
})

const answerSchema = yup.lazy(value => {
  if (Array.isArray(value)) {
    return answerWithCheckboxValidationSchema
  } else if (typeof value === 'number') {
    return answerNumberValidationSchema
  } else if (typeof value === 'boolean') {
    return answerTrueFalseValidationSchema
  }
  return answerStringValidationSchema
})

const otherAnswerValidationSchema = yup.mixed().when(['disabled', 'response'], {
  is: (disabled: boolean, response: any) => !disabled && Array.isArray(response) && response.includes(OTHER_OPTION),
  then: yup.string().trim().required("Required field when 'Other' is selected"),
  otherwise: yup.string().trim().nullable(true).notRequired(),
})

export const questionValidationSchema = yup.object().shape({
  disabled: yup.boolean().default(false),
  questionId: yup.number(),
  answerId: yup.number().notRequired(),
  question: yup.string(),
  options: yup.array(),
  questionType: yup.string(),
  isRequired: yup.boolean(),
  otherAnswer: otherAnswerValidationSchema,
})

const getSchemaByQuestionType = (questionType: string) => {
  switch (questionType as QuestionType) {
    case QuestionType.EMAIL:
      return answerEmailValidationSchema
    case QuestionType.PHONE_NUMBER:
      return answerPhoneValidationSchema
    case QuestionType.EMERGENCY_CONTACT:
      return answerContactValidationSchema
    case QuestionType.HOME_ADDRESS:
      return answerLocationValidationSchema
    default:
      break
  }

  return answerSchema
}

// @ts-ignore
export const answerValidationSchema = yup.lazy(({ disabled, questionType }) => {
  return yup
    .object()
    .shape({
      response: disabled ? yup.mixed().nullable(true).notRequired() : getSchemaByQuestionType(questionType),
    })
    .concat(questionValidationSchema)
})

export const answerSetValidationSchema = yup.object<QuestionSetResponse>().shape({
  answerSetId: yup.number().nullable().notRequired(),
  name: yup.string().nullable().notRequired(),
  // @ts-ignore
  organization: organizationSchema.optional(),
  questionSetId: yup.number().nullable().notRequired(),
  questions: yup.array<Question>().of(answerValidationSchema),
})
