import React from 'react'
import InviteForm from './InviteForm'
import { upperFirst } from 'lodash'

export default function VolunteerInvite(props) {
    const { invite } = props
    return (
        <InviteForm invite={invite}>
            <div className="invite-header">
                <h2>Hi {upperFirst(invite.recipientGivenName)}</h2>
                <p>{invite.organization.name} has invited you to join Civic Champs to help them track your volunteer hours.  Please complete our simple sign up below and we will get you connected to your account.</p>
            </div>
        </InviteForm>
    )
}
