import React, { useState, useCallback } from 'react'
import Welcome from './Welcome'
import { get } from 'lodash'
import { RequirementsModal } from 'civic-champs-shared/question-sets/components'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { useSelfApply } from '../../hooks'

import { invitationTypeToActionLookup } from 'civic-champs-shared/question-sets/types'
import { Button } from '@material-ui/core'

export default function AcceptRequirements({ person, group, orgSlugName, organization }) {
  const [inProgress, setInProgress] = useState(true)
  const fulfillRequirementsPrompt = useShowPrompt(RequirementsModal)
  const [selfApply] = useSelfApply()

  const membershipName = group.closed ? 'Applicant' : 'Member'

  const handleRequirements = useCallback(async () => {
    try {
      const done = await fulfillRequirementsPrompt({
        person,
        filters: {
          associatedEntity: {
            organizationId: organization.id,
            groupId: group.id,
          },
          personId: person.id,
          action: get(invitationTypeToActionLookup, 'custom_group'),
          freshAnswerSet: false,
          excludeCompletedAnswerSets: true,
          excludeCredentials: false,
        },
        isAdmin: false,
        continueEvenIfError: false,
        organizationId: organization.id,
      })
      if (done) {
        await selfApply({ groupId: group.id, orgSlugName, userId: person.id })
        setInProgress(false)
      }
    } catch (error) {
      console.error(error)
    }
  }, [fulfillRequirementsPrompt, group.id, orgSlugName, organization.id, person, selfApply])

  if (inProgress) {
    return (
      <div className="invite-header">
        <p style={{ textAlign: 'center' }}>
          Click the button below to continue with your application. Please agree to all of the following waivers and
          answer any the questions to become a {group.name} {membershipName} for {organization.name}.
        </p>
        <p style={{ textAlign: 'center' }}>Thank you!</p>
        <Button className="submit button" onClick={handleRequirements}>
          Continue with Application
        </Button>
      </div>
    )
  }

  return (
    <Welcome>
      <div className="invite-header">
        <p style={{ textAlign: 'center' }}>
          Thank you for completing your application to become an {membershipName} for the{' '}
          {`${organization.name} ${group.name}`} group.
        </p>
        <p>Someone will get back to you soon with your next steps.</p>
      </div>
    </Welcome>
  )
}
