import { every } from 'lodash'
import React, { useEffect, useState, useMemo } from 'react'
import { useSet } from 'react-use'
import { makeStyles, Button, Grid, InputLabel } from '@material-ui/core'
import * as _ from 'lodash'
import * as moment from 'moment-timezone'
import { Field, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import { DatePicker } from 'formik-material-ui-pickers'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import { useSignWaiver } from '../hooks'
import useFileManagementUpload from '../hooks/useFileManagementUpload'
import useCredentialTypesCollection from '../hooks/useCredentialTypesCollection'
import MomentUtils from '@date-io/moment'
import * as yup from 'yup'

import { CredentialSelect } from './CredentialSelect'

const useStyles = makeStyles(() => ({
  modalBody: {
    display: 'flex',
    backgroundColor: 'white',
    maxWidth: '80vw',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'auto',
    paddingBottom: '30px',
  },
  title: {
    fontSize: '21px',
  },
  errorText: {
    color: '#FF0000',
  },
  verticalMargin: {
    margin: '10px 0',
  },
  formVerticalSpace: {
    margin: '30px 0',
  },
  image: {
    maxWidth: '50vw',
  },
  fullWidth: {
    width: '100%',
  },
  container: {
    width: '211px',
  },
}))

export default function CredentialIds(props) {
  const { complete, credentialIds, user, organizationId } = props
  const [selectedCredentialType, setSelectedCredentialType] = useState('')
  const [uploadingFile, setUploadingFile] = useState(false)

  const [{ credentialIDTypes }] = useCredentialTypesCollection(organizationId)

  const classes = useStyles()

  const [signWaiver, { loading: signingWaiver }] = useSignWaiver()
  const [signedWaiverIds, { add, has }] = useSet()

  const isSigned = credential => has(credential.credential.id)

  const unsignedCredentialIds = useMemo(
    () => credentialIds.filter(waiver => !has(waiver.credential.id)),
    [credentialIds, has],
  )
  const credential = unsignedCredentialIds[0]

  const handleTypeChange = (value, form) => {
    setSelectedCredentialType(value)
    form.setFieldValue('attachmentIds', [])
    form.setFieldValue('selectedCredentialTypeID', value.id)
    setTimeout(form.validateForm, 100)
  }

  const handleSigning = async (credential, values, setFieldValue) => {
    try {
      const credentialID = credential.credential.id
      const { associatedEntity } = credential

      if (signingWaiver || has(credentialID)) return
      const images = values.attachmentIds.map(a => a.id)

      const signatureOptions = {
        personId: user.id,
        credentialId: credentialID,
        associatedEntity,
        expiredAt: values.expirationDate.format('MM/DD/YYYY'),
        identificationType: selectedCredentialType.label,
        attachmentIds: images,
        identificationTypeOther: values.identificationTypeOther,
      }

      await signWaiver(signatureOptions)

      add(credentialID)
      setFieldValue('attachmentIds', [])
      setFieldValue('expirationDate', new Date())
    } catch {}
  }

  useEffect(() => {
    if (every(credentialIds, waiver => signedWaiverIds.has(waiver.credential.id))) {
      complete()
    }
  }, [credentialIds, signedWaiverIds, complete])

  const { uploadFile } = useFileManagementUpload(organizationId)

  const upload = async (file, uploadedFor, index, setFieldValue) => {
    setUploadingFile(true)
    try {
      const uploadedFile = await uploadFile(file, uploadedFor)
      setFieldValue(`attachmentIds[${index}]`, { id: uploadedFile.id, url: uploadedFile.downloadUrl })
    } finally {
      setUploadingFile(false)
    }
  }

  const FormikImageUpload = props => {
    return (
      <Grid item className={classes.verticalMargin}>
        <div>
          <p>Image Selection:</p>
          <input
            name="attachmentIds"
            accept="image/*"
            id={`${props.index}-file-upload-input`}
            type="file"
            style={{ display: 'none' }}
            onChange={async e =>
              upload(_.first(e.target.files), 'credential_id_image', props.index, props.form.setFieldValue)
            }
          />

          <label htmlFor={`${props.index}-file-upload-input`}>
            <Button variant="contained" color="primary" component="span" disabled={uploadingFile}>
              Upload Image
            </Button>
          </label>
          {props.field.value && (
            <div className={classes.verticalMargin}>
              <p>Image {props.index + 1}:</p>
              <img src={props.field.value.url} className={classes.image} alt="" />
            </div>
          )}
        </div>
      </Grid>
    )
  }

  const FormikDatePicker = props => {
    return (
      <>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <InputLabel id="expiration-date-label">Expiration Date</InputLabel>
          <Field
            component={DatePicker}
            name={props.field.name}
            format="MM/DD/YYYY"
            minDate={new Date()}
            variant="contained"
            inputVariant="outlined"
          />
        </MuiPickersUtilsProvider>
      </>
    )
  }

  const credentialIDSchema = yup.object({
    expirationDate: yup
      .date()
      .required()
      .test('inFuture', 'Expiration date must be in the future.', function (value) {
        return moment(value).isAfter()
      }),
    attachmentIds: yup.lazy(() => {
      return selectedCredentialType.minNumAttachments
        ? yup
            .array()
            .min(
              selectedCredentialType.minNumAttachments,
              `This credential requires ${selectedCredentialType.minNumAttachments} images.`,
            )
        : yup.array().min(1, 'Credential image upload required.')
    }),
    identificationTypeOther: yup.lazy(() => {
      return selectedCredentialType.label === 'Other' ? yup.string().required() : yup.string().notRequired()
    }),
    selectedCredentialTypeID: yup.number().required('Please select an ID type.'),
  })

  return (
    <div className={classes.modalBody}>
      <div className={classes.title}>
        <b>Identification</b>
        {credential && credential.version && (
          <div>
            <p>{credential.version.title}</p>
            <div
              dangerouslySetInnerHTML={{
                __html: credential.version.content,
              }}
            />
          </div>
        )}
      </div>

      {credential && (
        <Formik
          initialValues={{
            expirationDate: new Date(),
            attachmentIds: [],
            identificationTypeOther: '',
            selectedCredentialTypeID: '',
          }}
          validationSchema={credentialIDSchema}
          onSubmit={async (values, { setFieldValue }) => {
            await handleSigning(credential, values, setFieldValue)
          }}
        >
          {({ errors, handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit} noValidate>
                <Grid container direction="column" className={classes.formVerticalSpace}>
                  <Grid item>
                    <div className={classes.container}>
                      <Field
                        component={CredentialSelect}
                        handleTypeChange={handleTypeChange}
                        selectedCredentialType={selectedCredentialType}
                        name="selectedCredentialTypeID"
                        credentialIDTypes={credentialIDTypes}
                        classes={classes}
                      />
                    </div>
                  </Grid>
                  <Grid item>
                    <div className={classes.container}>
                      <Field component={FormikDatePicker} name="expirationDate" errors={errors} />
                    </div>
                  </Grid>
                  <Grid>
                    {selectedCredentialType.minNumAttachments ? (
                      <div>
                        {Array.from(Array(selectedCredentialType.minNumAttachments), (e, i) => (
                          <div key={i}>
                            <Field component={FormikImageUpload} name={`attachmentIds[${i}]`} index={i} />
                          </div>
                        ))}
                      </div>
                    ) : null}
                    {selectedCredentialType.minNumAttachments && errors.attachmentIds ? (
                      <div className={classes.errorText}>{errors.attachmentIds}</div>
                    ) : null}

                    {selectedCredentialType &&
                      (selectedCredentialType.label === 'Other' ? (
                        <Field component={TextField} name="identificationTypeOther" />
                      ) : null)}
                  </Grid>
                  <Grid item sm={8} className={classes.verticalMargin}>
                    <Button
                      fullWidth
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSigned(credential || signingWaiver)}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )
          }}
        </Formik>
      )}
    </div>
  )
}
