import React from 'react'
import { Button, Link, Grid } from '@material-ui/core'
import { stepOneSchema } from '../../schemas'
import { Field, Formik } from 'formik'
import { TextField } from 'formik-material-ui'

import { PhoneField } from 'civic-champs-shared/formik/components'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'

function StepOne(props) {
  const { onSubmit, onCancel, email, phoneNumber, goToStep, classes } = props
  const isAboveSmall = isWidthUp('sm', props.width)

  return (
    <>
      <div className="invite-header">
        <p style={{ textAlign: 'center' }}>
          To apply provide one or both of the following contacts. If you already have a Civic Champs account we will
          find it or you can{' '}
          <Link
            onClick={async () => {
              const nextStep = await onCancel()
              goToStep(nextStep)
            }}
          >
            click here to login
          </Link>
        </p>
      </div>
      <Formik
        initialValues={{
          email,
          phoneNumber,
        }}
        validationSchema={stepOneSchema}
        onSubmit={values => onSubmit(stepOneSchema.cast(values))}
        validateOnBlur={true}
        validateOnChange={false}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <form>
            <Grid container spacing={2} direction="column" justify="center" alignItems="center">
              <Grid item container spacing={2} justify="center">
                <Grid item>
                  <Field
                    fullWidth={!isAboveSmall}
                    component={TextField}
                    label="Email"
                    name="email"
                    variant="outlined"
                    className={classes.input}
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={2} justify="center">
                <Grid item>
                  <Field
                    fullWidth={!isAboveSmall}
                    component={PhoneField}
                    label="Mobile Number"
                    name="phoneNumber"
                    variant="outlined"
                    className={classes.input}
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={2} justify="center">
                <Button
                  type="submit"
                  className="submit button"
                  disabled={isSubmitting || (!values.email && !values.phoneNumber)}
                  onClick={handleSubmit}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  )
}

export default withWidth()(StepOne)
