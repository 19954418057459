import * as React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import Invites from './invite/components/Invites'
import Applications from './group-application/components/Applications'
import AllSet from './invite/components/valid/AllSet'
import PageHeader from './app/components/PageHeader'

import { UserContext } from 'auth/UserProvider'
import Loading from 'civic-champs-shared/core/Loading'
import useGoogleAnalytics from 'civic-champs-shared/utils/useGoogleAnalytics'
import EditFeedback from 'mentorship/EditFeedback'
import ViewFeedback from 'mentorship/ViewFeedback'

export default function Routes() {
  useGoogleAnalytics()

  return (
    <UserContext.Consumer>
      {({ user, loading }) => (
        <Router>
          <PageHeader currentUser={user} />
          {loading ? (
            <Loading size={60} />
          ) : (
            <>
              <Route path="/invite/:token">
                <Invites />
              </Route>
              <Route path="/apply/:orgSlugName/:groupId">
                <Applications currentUser={user} />
              </Route>
              <Route path="/all-set" component={AllSet} />
              <Route exact={true} path="/meetings/:meetingId/feedback/:type" component={EditFeedback} />
              <Route exact={true} path="/meetings/:meetingId/feedback/:type/view" component={ViewFeedback} />
              <Route path="*" />
            </>
          )}
        </Router>
      )}
    </UserContext.Consumer>
  )
}
