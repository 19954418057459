import { castArray } from 'lodash'
import { useMemo, useRef, useState } from 'react'
import useLazyRef from '../../utils/useLazyRef'
import EventEmitter from '../../utils/EventEmitter'

const useRemoteCollection = () => {
  const [items, setItems] = useState([])
  const versionRef = useRef(0)
  const emitterRef = useLazyRef(() => new EventEmitter())

  const operations = useMemo(
    () => ({
      eagerReplace: item => {
        let oldItem = null
        setItems(prev =>
          prev.map(i => {
            if (i.id === item.id) {
              oldItem = i
              return item
            } else {
              return i
            }
          }),
        )

        emitterRef.current.emit('updated', item)

        const currVersion = versionRef.current
        return function undoReplace() {
          if (versionRef.current !== currVersion) return
          setItems(prev => prev.map(i => (i.id === oldItem.id ? oldItem : i)))
          emitterRef.current.emit('updated', oldItem)
        }
      },
      eagerAdd: item => {
        const itemArr = castArray(item)
        const itemIds = itemArr.map(item => item.id)
        setItems(prev => [...itemArr, ...prev])
        emitterRef.current.emit('added', item)

        const currVersion = versionRef.current
        return function undoAdd() {
          if (versionRef.current !== currVersion) return
          setItems(prev => prev.filter(i => !itemIds.includes(i.id)))
          emitterRef.current.emit('removed', item)
        }
      },
      eagerRemove: item => {
        setItems(prev => prev.filter(i => i.id !== item.id))

        const currVersion = versionRef.current
        return function undoRemove() {
          if (versionRef.current !== currVersion) return
          setItems(prev => [item, ...prev]) //TODO how to recover sort order!?
          emitterRef.current.emit('added', item)
        }
      },
      eagerRemoveMany: items => {
        const ids = items.map(item => item.id)
        setItems(prev => prev.filter(i => ids.indexOf(i.id) < 0))

        const currVersion = versionRef.current
        return function undoRemove() {
          if (versionRef.current !== currVersion) return
          setItems(prev => [...items, ...prev]) //TODO how to recover sort order!?
          emitterRef.current.emit('added', items)
        }
      },
      syncCollection: newItems => {
        versionRef.current++
        setItems(newItems)
        emitterRef.current.emit('synced', newItems)
      },
    }),
    [setItems, versionRef, emitterRef], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const events = useMemo(
    () => ({
      onAdd: callback => emitterRef.current.listen('added', callback),
      onRemove: callback => emitterRef.current.listen('removed', callback),
      onUpdate: callback => emitterRef.current.listen('updated', callback),
      onSync: callback => emitterRef.current.listen('synced', callback),
    }),
    [emitterRef],
  )

  return [items, operations, events]
}

export default useRemoteCollection
