// TODO: think about what to show when all requirements have been satisfied
// if there are no requirements to begin with, the modal just pops up and then disappears immediately
// which is an awkward experience
import React from 'react'
import { IconButton, makeStyles, Modal } from '@material-ui/core'
import { RequirementsContainer } from './RequirementsContainer'
import Close from '@material-ui/icons/Close'
import { isRNWebView } from '../../constants/system'

const useStyles = makeStyles(() => ({
  modalBody: {
    display: 'flex',
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },
  modal: {
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    alignSelf: 'flex-end',
    width: '60px',
    height: '60px',
    borderRadius: 0,
    color: 'white',
    backgroundColor: '#FF6F3E',
  },
  title: {
    fontSize: '21px',
  },
  smallTitle: {
    fontSize: '16px',
    width: '500px',
    marginTop: '40px',
    marginBottom: '20px',
  },
  text: {
    fontSize: '16px',
    width: '500px',
    marginBottom: '50px',
  },
  waiverTitle: {
    fontSize: '18px',
    width: '500px',
    marginBottom: '20px',
  },
  readFull: {
    fontSize: '17px',
    paddingLeft: '4px',
  },
  waiverText: {
    fontSize: '16px',
  },
  modalButton: {
    width: '146px',
    height: '45px',
    background: '#5F8FE8',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    marginTop: '30px',
  },
  disabledButton: {
    width: '146px',
    height: '45px',
    background: 'grey',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    marginTop: '30px',
  },
  nextText: {
    color: 'white',
    fontSize: '15px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '460px',
    alignItems: 'center',
  },
}))

export default function RequirementsModal(props) {
  const { showing, complete, filters, person, isAdmin = false, continueEvenIfError = false, organizationId } = props
  const classes = useStyles()
  const onCompleted = success => complete(success)
  const handleClose = () => complete(false)
  return (
    <Modal open={showing} className={classes.modal}>
      <div className={classes.modalBody}>
        {!isRNWebView && (
          <IconButton className={classes.icon} aria-label="close" onClick={handleClose}>
            <Close />
          </IconButton>
        )}
        <RequirementsContainer
          filters={filters}
          onCompleted={onCompleted}
          person={person}
          isAdmin={isAdmin}
          continueEvenIfError={continueEvenIfError}
          organizationId={organizationId}
        />
      </div>
    </Modal>
  )
}
