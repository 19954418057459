import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import appStoreQR from '../../../images/CivicChampsAppleStoreAppQRCode.png'
import googleStoreQR from '../../../images/CivicChampsGooglePlayStoreAppQRCode.png'

export const DesktopQRCodes = () => (
    <>
        <Grid item xs={12}>
            <div className="invite-header">
                <p>Get the app by scanning the code below that matches your device.</p>
            </div>
        </Grid>
        <Grid container direction="row" alignItems="center" justify="center">
            <Grid item container xs={2} alignItems="center" justify="center" direction="column">
                <Grid item>
                    <Typography variant="h4">Apple</Typography>
                </Grid>
                <Grid item>
                    <img alt="QR code for link to app store" src={appStoreQR} />
                </Grid>
            </Grid>
            <Grid item container xs={2} alignItems="center" justify="center" direction="column">
                <Grid item>
                    <Typography variant="h4">Android</Typography>
                </Grid>
                <Grid item>
                    <img alt="QR code for ink to google store" src={googleStoreQR} />
                </Grid>
            </Grid>
        </Grid>
    </>
)
