import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useFetchMentoringMeeting } from 'civic-champs-shared/question-sets/hooks/useFetchMentoringMeeting'
import { QuestionAnsweringForm } from 'civic-champs-shared/question-sets/components/question-set-answering/QuestionAnsweringForm'
import Loading from 'civic-champs-shared/core/Loading'
import { Field } from 'formik'
import { TextareaComponent } from 'civic-champs-shared/question-sets/components/question-set-answering/base/textarea'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import { makeStyles } from '@material-ui/styles'
import { useSubmitFeedback } from 'civic-champs-shared/api/hooks/useSubmitFeedback'
import find from 'lodash/find'
import { isUndefined } from 'lodash'

const useStyles = makeStyles(() => ({
  radio: {
    display: 'none',
    '& + svg': {
      opacity: 0.5,
      cursor: 'pointer',
    },
    '&:checked + svg': {
      opacity: 1,
    },
  },
  radioContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  backButton: {
    backgroundColor: 'rgba(100,100,100,0.1)',
    position: 'fixed',
    left: 8,
    top: 16,
  },
}))
function useQueryToken() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search).get('token'), [search])
}

// @ts-ignore
const targetWindow = window.ReactNativeWebView

export default function EditFeedback(route: any) {
  const {
    match: {
      params: { meetingId, type },
    },
  } = route
  const token = useQueryToken()
  useEffect(() => {
    token && localStorage.setItem('secureToken', `scheduler:${token}`)
  }, [token])
  // @ts-ignore
  const [fetchQuestionSets, { loading, result }] =
    // @ts-ignore
    useFetchMentoringMeeting()

  useEffect(() => {
    // @ts-ignore
    fetchQuestionSets(meetingId)
  }, [fetchQuestionSets, token, meetingId])
  const classes = useStyles()
  const feedback = find(result?.feedback, { type })
  const [readonly, setReadonly] = useState(typeof feedback !== 'undefined')
  const onCancel = () => {
    setReadonly(true)
    onBack()
  }
  const onEdit = () => setReadonly(false)
  const onSuccess = () => {
    targetWindow && targetWindow.postMessage('created')
    setReadonly(true)
  }
  const onBack = () => {
    targetWindow && targetWindow.postMessage('back')
  }
  const [submitFeedback] = useSubmitFeedback(onSuccess)

  const onSubmit = ({ rating: rateValue, ...data }: any) => {
    const rating = isUndefined(rateValue) ? rateValue : parseInt(rateValue) === 1
    // @ts-ignore
    return submitFeedback({ ...data, meetingId, type, rating })
  }
  const questionSet =
    feedback?.answersSet || (type === 'mentor' ? result?.mentorQuestionSet : result?.menteeQuestionSet)
  let rating
  if (typeof feedback !== 'undefined') {
    rating = feedback.rating ? 1 : 0
  }
  useEffect(() => {
    !loading && targetWindow?.postMessage('loaded')
  }, [loading])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <QuestionAnsweringForm
          onSubmit={onSubmit}
          questionSet={questionSet}
          onCancel={onCancel}
          onEdit={onEdit}
          required={true}
          heading="How was your meeting?"
          readonly={readonly}
          additionalInitialValues={{
            rating,
            notes: feedback?.notes,
          }}
        >
          <Field name="rating">
            {({ field }: { field: any; form: any; meta: any }) => (
              <div className={classes.radioContainer}>
                <label>
                  <input
                    type="radio"
                    name={field.name}
                    id="positive-feedback"
                    value={1}
                    checked={parseInt(field.value) === 1}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    className={classes.radio}
                    disabled={readonly}
                  />
                  <ThumbUpIcon fontSize="large" />
                </label>
                <label>
                  <input
                    type="radio"
                    id="negative-feedback"
                    name={field.name}
                    value={0}
                    checked={parseInt(field.value) === 0}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    className={classes.radio}
                    disabled={readonly}
                  />
                  <ThumbDownIcon fontSize="large" />
                </label>
              </div>
            )}
          </Field>
          <Field name="notes">
            {({ field, meta }: { field: any; form: any; meta: any }) => (
              <div className="feedback-form-block-question-list-item">
                <div className="feedback-form-block-question-list-item__question">{'Personal Notes'}</div>
                <div className="feedback-form-block-question-list-item__value">
                  <TextareaComponent {...field} readonly={readonly} />
                </div>
                {meta.touched && meta.error && <div className="feedback-error-message">{meta.error}</div>}
              </div>
            )}
          </Field>
        </QuestionAnsweringForm>
      )}
    </>
  )
}
