import React, { forwardRef, useCallback } from 'react'
import { SnackbarContent, SnackbarContentProps, useSnackbar } from 'notistack'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import Typography from '@material-ui/core/Typography/Typography'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(() => ({
  root: {
    '@media (min-width:600px)': {
      minWidth: '344px !important',
    },
  },
  base: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '6px 21px 6px 6px',
    gap: '16px',
    width: '346px',
    height: '62px',
    background: '#F8FAFF',
    border: '1px solid #E1E1E2',
    boxShadow: '0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)',
    borderRadius: '50px',
    position: 'relative',
  },
  iconWrapper: {
    width: '50px',
    height: '50px',
    background: 'rgba(192, 255, 216, 0.5)',
    borderRadius: '50px',
    flexShrink: 0,
    padding: '12px',
  },
  title: {
    width: '203px',
    height: '24px',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.1px',
    color: '#000000',
  },
  text: {
    width: '225px',
    height: '16px',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#000000',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  close: {
    color: '#747778',
    position: 'absolute',
    right: '20px',
    top: '18px',
    cursor: 'pointer',
  },
  textWrapper: {
    marginTop: '3px',
    marginLeft: '5px',
  },
}))

export interface CustomProps extends SnackbarContentProps {
  message: React.ReactNode
}

export const Success = forwardRef<HTMLDivElement, CustomProps>(({ id, message, ...props }, ref) => {
  const classes = useStyles()
  const { closeSnackbar } = useSnackbar()

  const handleDismiss = useCallback(() => {
    closeSnackbar(id)
  }, [id, closeSnackbar])

  return (
    <SnackbarContent ref={ref} className={classes.root}>
      <div className={classes.base}>
        <div className={classes.iconWrapper}>
          <CheckCircleOutlineIcon style={{ color: '#008954' }} />
        </div>
        <div className={classes.textWrapper}>
          <Typography className={classes.title}>Success</Typography>
          <Typography className={classes.text}>{message}</Typography>
        </div>
        <CloseIcon className={classes.close} onClick={handleDismiss} />
      </div>
    </SnackbarContent>
  )
})

Success.displayName = 'Success'

export default Success
