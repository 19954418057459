import { isNumber, isString, size } from 'lodash'
import humanFormat from 'human-format'
import moment from 'moment'

const toFixed = (value, precision) => {
  let number = isNumber(value) ? value : Number(value) //humanFormat(value || 0, { decimals: 1 });
  return number.toFixed(precision)
}

const toFormattedDate = (value, format, convertToLocalTime) => {
  if (value != null) {
    let date = moment(value)

    return date.isValid() ? (convertToLocalTime ? date.local().format(format) : date.format(format)) : null
  }

  return null
}

const formatCount = collection => humanFormat(size(collection), { decimals: 1 })

/*const formatNumber = (total) => format(total || 0, { decimals: 1 });*/

const formatHours = (hours, decimals = 0) =>
  hours > 100000
    ? humanFormat(hours || 0, { decimals: 1 })
    : Intl.NumberFormat('en', { style: 'decimal', maximumFractionDigits: decimals }).format(hours || 0)

const formatDate = date => toFormattedDate(date, 'MM/DD/YYYY')

const formatTime = (dateTime, convertToLocalTime = true) => toFormattedDate(dateTime, 'h:mm a', convertToLocalTime)

const formatRelativeTime = time => {
  const now = moment()
  const days = now.diff(time, 'days')
  if (days) {
    return `${days}d ago`
  }
  const hours = now.diff(time, 'hours')
  if (hours) {
    return `${hours}h ago`
  }
  const minutes = now.diff(time, 'minutes')
  if (minutes) {
    return `${minutes}m ago`
  }
  return 'Now'
}

const formatDateTime = (dateTime, convertToLocalTime = true) =>
  toFormattedDate(dateTime, 'MM/DD/YYYY hh:mm a', convertToLocalTime)
const formatDateTimeForExport = (dateTime, convertToLocalTime = true) =>
  toFormattedDate(dateTime, 'MM/DD/YYYY hh:mm:ss a', convertToLocalTime)

const formatNumber = total =>
  total > 100000
    ? humanFormat(total || 0, { decimals: 1 })
    : Intl.NumberFormat('en', { style: 'decimal', maximumFractionDigits: 0 }).format(total || 0)

const formatCurrency = amount =>
  amount > 100000
    ? '$' + humanFormat(amount || 0, { decimals: 1 })
    : '$' + Intl.NumberFormat('en', { style: 'decimal', maximumFractionDigits: 0 }).format(amount || 0)

const usdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

//TODO this "real thing" should be called 'currency'
const formatMoney = amount => usdFormatter.format(amount)

const formatPhoneNumber = phone => {
  if (!isString(phone)) {
    return isNumber(phone) ? formatPhoneNumber('' + phone) : null
  }

  if (/^[-+()\d\s]+$/.test(phone)) {
    let rawPhone = phone.replace(/[^\d]+/g, '')
    if (rawPhone.length <= 10) {
      let areaCode = rawPhone.substr(0, 3)
      let prefix = rawPhone.substr(3, 3)
      let line = rawPhone.substr(6, 4)

      return `(${areaCode}) ${prefix}-${line}`
    } else if (rawPhone.length === 11) {
      let countryCode = rawPhone.charAt(0)
      let areaCode = rawPhone.substr(1, 3)
      let prefix = rawPhone.substr(4, 3)
      let line = rawPhone.substr(7, 4)

      return Number(countryCode) === 1
        ? `(${areaCode}) ${prefix}-${line}`
        : `${countryCode}-${areaCode}-${prefix}-${line}`
    } else {
      return `+${rawPhone}`
    }
  } else {
    return null
  }
}

const formatName = person => {
  if (!person) return ''
  return `${person.givenName && person.givenName} ${person.familyName && person.familyName}`
}

const toUrlDate = datetime => (datetime ? moment(datetime).utc().format('YYYY-MM-DD') : null)

const formatAddress = address => {
  if (!address) return ''
  const { line1, line2, city, state, zip } = address
  return `${line1}${line2 || ''}, ${city}, ${state} ${zip}`
}

export const DEFAULT_TIME_ZONE = 'America/New_York'

export default {
  count: formatCount,
  number: formatNumber,
  hours: formatHours,
  date: formatDate,
  dateForUrl: toUrlDate,
  datetime: formatDateTime,
  datetimeForExport: formatDateTimeForExport,
  fixedPoint: toFixed,
  currency: formatCurrency,
  money: formatMoney, //TODO this should be currency >_>
  phoneNumber: formatPhoneNumber,
  name: formatName,
  timeAgo: formatRelativeTime,
  time: formatTime,
  address: formatAddress,
}
