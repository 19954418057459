import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useFetchMentoringMeeting } from 'civic-champs-shared/question-sets/hooks/useFetchMentoringMeeting'
import { Answers } from 'civic-champs-shared/question-sets/components'
import Loading from 'civic-champs-shared/core/Loading'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import { makeStyles } from '@material-ui/styles'
import find from 'lodash/find'
import { isUndefined } from 'lodash'

// @ts-ignore
const useStyles = makeStyles(theme => ({
  wrapped: {
    maxWidth: '868px',
    margin: '50px auto',
    // @ts-ignore
    [theme.breakpoints.down('md')]: {
      padding: '0 16px',
    },
  },
  question: {
    fontSize: '18px',
    fontFamily: 'Magallanes',
    lineHeight: 1.24,
  },
  answer: {
    fontSize: '16px',
    fontFamily: 'Josefin Sans',
    lineHeight: 1.5,
  },
}))
function useQueryToken() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search).get('token'), [search])
}

// @ts-ignore
const targetWindow = window.ReactNativeWebView

export default function ViewFeedback(route: any) {
  const {
    match: {
      params: { meetingId, type },
    },
  } = route
  const token = useQueryToken()
  useEffect(() => {
    token && localStorage.setItem('secureToken', `scheduler:${token}`)
  }, [token])
  // @ts-ignore
  const [fetchQuestionSets, { loading, result }] =
    // @ts-ignore
    useFetchMentoringMeeting()

  useEffect(() => {
    // @ts-ignore
    fetchQuestionSets(meetingId)
  }, [fetchQuestionSets, token, meetingId])
  const classes = useStyles()
  const feedback = find(result?.feedback, { type })
  useEffect(() => {
    !loading && targetWindow?.postMessage('loaded')
  }, [loading])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className={classes.wrapped}>
          {feedback ? (
            <>
              {!isUndefined(feedback.rating) && (
                <div>
                  <label className={classes.question}>
                    <b>Rating</b>
                  </label>
                  <p className={classes.answer}>
                    <i>{feedback.rating ? <ThumbUpIcon fontSize="large" /> : <ThumbDownIcon fontSize="large" />}</i>
                  </p>
                </div>
              )}
              {feedback.notes && (
                <div>
                  <label className={classes.question}>
                    <b>Personal Notes</b>
                  </label>
                  <p className={classes.answer}>
                    <i>{feedback.notes}</i>
                  </p>
                </div>
              )}
              <Answers
                answers={feedback.answersSet.questions}
                questionClassName={classes.question}
                answerClassName={classes.answer}
              />
            </>
          ) : (
            'No Feedback Provided'
          )}
        </div>
      )}
    </>
  )
}
