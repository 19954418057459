import React, { useMemo } from 'react'
import { Grid } from '@material-ui/core'
import { isAndroid, isIOS } from 'react-device-detect'
import { LinkButton } from '../../../common/components/LinkButton'

import appStoreQR from '../../../images/CivicChampsAppQRCode.png'

export default function Welcome(props) {
  const { children } = props

  const linkComponent = useMemo(() => {
    if (isIOS) {
      return (
        <LinkButton
          link="https://apps.apple.com/us/app/civic-champs/id1460594845"
          description="Get the app by following the link below."
        />
      )
    } else if (isAndroid) {
      return (
        <LinkButton
          link="https://play.google.com/store/apps/details?id=com.civicchamps&hl=en_US"
          description="Get the app by following the link below."
        />
      )
    } else {
      return (
        <Grid container direction="row" alignItems="center" justify="center">
          <Grid item container xs={2} alignItems="center" justify="center" direction="column">
            <Grid item>
              <img alt="QR code for link to app store" width="200px" src={appStoreQR} />
            </Grid>
          </Grid>
        </Grid>
      )
    }
  }, [])

  return (
    <>
      {children}
      <Grid container direction="column" justify="center" alignItems="center">
        {linkComponent}
      </Grid>
    </>
  )
}
