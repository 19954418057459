import React, { useCallback } from 'react'
import { useSnackbar } from 'notistack'
import Success from '../../core/snackbars/Success'
import useIntercomWidget from '../../utils/useIntercomWidget'

const useSuccessNotification = (isNew = false) => {
  const { enqueueSnackbar } = useSnackbar()
  const { showWidget, hideWidget } = useIntercomWidget()

  return useCallback(
    msg => {
      if (isNew) {
        hideWidget()
      }

      return enqueueSnackbar(msg, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: isNew ? 'right' : 'center',
        },
        ...(isNew
          ? {
              // @ts-ignore
              content: (key, message) => <Success id={key} message={message} />,
            }
          : {}),
        onClose: () => {
          if (isNew) {
            showWidget()
          }
        },
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueSnackbar, isNew],
  )
}

export default useSuccessNotification
