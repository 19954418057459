import { FormControlLabel, Switch } from '@material-ui/core'
import React from 'react'
import { FieldProps } from 'formik'

export interface ToggleProps extends FieldProps {
  label: string
}

export default function SwitchToggle({
  field: { value, name, onChange }, // { name, value, onChange, onBlur }
  label,
}: ToggleProps) {
  return <FormControlLabel control={<Switch name={name} checked={value} onChange={onChange} />} label={label} />
}
