import React, { useCallback } from 'react'

// bad invites
import InviteRejected from '../components/invalid/InviteRejected'
import InviteRevoked from '../components/invalid/InviteRevoked'
import InviteNotFound from '../components/invalid/InviteNotFound'
import InviteExpired from '../components/invalid/InviteExpired'
import InviteExpiredSentNew from '../components/invalid/InviteExpiredSentNew'
import InviteAlreadyAcceptedLogin from '../components/invalid/InviteAlreadyAcceptedLogin'

// active invites
import InviteAlreadyAccepted from '../components/valid/InviteAlreadyAccepted'
import VolunteerInvite from '../components/valid/VolunteerInvite'
import MemberInvite from '../components/valid/MemberInvite'
import MentorInvite from '../components/valid/MentorInvite'
import KioskInvite from '../components/valid/KioskInvite'
import SignUpInvite from '../components/valid/SignUpInvite'
import CustomGroupInvite from '../components/valid/CustomGroupInvite'
import TaskRecipientInvite from '../components/valid/TaskRecipientInvite'
import TaskVolunteerInvite from '../components/valid/TaskVolunteerInvite'
import TaskRequesterInvite from '../components/valid/TaskRequesterInvite'
import MenteeInvite from '../components/valid/MenteeInvite'
import SelfServePurchaseInvite from '../components/valid/SelfServePurchaseInvite'

const cannotProceedLookup = {
  rejected: InviteRejected,
  revoked: InviteRevoked,
  expired: InviteExpired,
  'expired-resent-new': InviteExpiredSentNew,
  accepted: InviteAlreadyAcceptedLogin,
}

const invitationTypeLookup = {
  volunteering: VolunteerInvite,
  'volunteering-verify': VolunteerInvite,
  membership: MemberInvite,
  mentorship: MentorInvite,
  mentee: MenteeInvite,
  kiosk: KioskInvite,
  'sign-up': SignUpInvite,
  'custom-group': CustomGroupInvite,
  'task-requester': TaskRequesterInvite,
  'task-recipient': TaskRecipientInvite,
  'task-volunteer': TaskVolunteerInvite,
  'self-serve-purchase': SelfServePurchaseInvite,
}

const useInviteComponent = ({ loading, canProceed, status, invitation, person }) => {
  const getComponent = useCallback(() => {
    if (loading) return null

    if (!canProceed) {
      const Component = cannotProceedLookup[status] || InviteNotFound
      return <Component invite={invitation} />
    } else if (person && person.cognitoSub) {
      return <InviteAlreadyAccepted invite={invitation} />
    } else if (invitation) {
      const Component = invitationTypeLookup[invitation.invitationType] || InviteNotFound
      return <Component invite={invitation} />
    }
    return <InviteNotFound />
  }, [canProceed, invitation, loading, person, status])
  return getComponent()
}

export default useInviteComponent
