import React, { KeyboardEvent, useCallback, useEffect, useState } from 'react'
import { Box, Grid, TextField as MuiTextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { FieldProps } from 'formik'
import _ from 'lodash'
import { PlacePredictionOption } from './PlacePredictionOption'
import { DEFAULT_GEOFENCING } from '../../constants/GEO_DATA'
import { MAX_RADIUS_IN_METER, MIN_RADIUS_IN_METER } from '../../helpers/toRoundMetersTo5Feet'
import GeofenceRadiusSlider from '../../core/geofence-radius-slider'
import ReactGoogleMap, { MAP } from '../../core/location/ReactGoogleMap'
import { Geofencing } from '../../core/location/utils'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'

export const defaultAddress: () => Address = () => ({
  city: '',
  state: '',
  zip: '',
  line1: '',
  line2: '',
  geofencing: DEFAULT_GEOFENCING,
  country: '',
  county: '',
  utcOffset: 0,
})

export interface Address {
  line1: string
  line2: string
  city: string
  state: string
  zip: string
  geofencing: Geofencing
  country: string
  county: string
  utcOffset: number
}

export interface PredictionAndPlaceResults {
  predictionResults?: google.maps.places.AutocompletePrediction[]
  placeResults?: google.maps.places.PlaceResult[]
}

export interface AddressFieldProps extends FieldProps {
  showRadius?: boolean
  fieldTitle?: string
  label?: string
  hideMap?: boolean
  placeholder?: string
  loadLocationFromAddress?: boolean
  vertical?: boolean
  smaller?: boolean
}

const googleService: {
  autoComplete?: google.maps.places.AutocompleteService
  places?: google.maps.places.PlacesService
} = {}

export type GoogleAutoCompleteMixedResponse = google.maps.places.AutocompletePrediction | google.maps.places.PlaceResult

const useStyles = makeStyles({
  verticalGap: {
    gap: '10px',
  },
  smaller: {
    padding: '3px 9px 2px 0px !important',
  },
  smallerInput: {
    padding: ' 8.5px 9px',
  },
  smallerFocused: {
    '& > fieldset': {
      borderColor: 'inherit !important',
      borderWidth: '1px !important',
    },
  },
})

export const AddressField = (props: AddressFieldProps) => {
  const {
    form: { setFieldValue, errors, touched, setTouched },
    field: { name, value },
    showRadius,
    fieldTitle,
    label,
    hideMap,
    placeholder,
    loadLocationFromAddress,
    vertical,
    smaller,
  } = props
  const addressError = _.get(touched, name) && _.get(errors, name)
  const [line1Options, setLine1Options] = useState<GoogleAutoCompleteMixedResponse[]>([])
  const [optionsFetching, setOptionsFetching] = useState<boolean>(false)
  const [enterPressed, setEnterPressed] = useState<boolean>(false)
  const [selectFirstOptionDelayed, setSelectFirstOptionDelayed] = useState<boolean>(false)
  const { geofencing = DEFAULT_GEOFENCING } = value || {}

  const getDetails = React.useMemo(
    () =>
      _.throttle(
        (
          request: { placeId: string },
          callback: (result: google.maps.places.PlaceResult, status: google.maps.places.PlacesServiceStatus) => void,
        ) => {
          if (googleService.places) googleService.places.getDetails(request, callback)
        },
        200,
      ),
    [],
  )

  const handleOptionSelect = useCallback(
    (e: React.ChangeEvent<{}> | null, v: GoogleAutoCompleteMixedResponse) => {
      if (_.isEmpty(v) || !v.place_id) return
      getDetails({ placeId: v.place_id }, r => {
        const { state, zip, city, geofencing, country } = parsePlace(r, value?.geofencing?.radius)
        const description = 'description' in v ? v.description : r.name
        setFieldValue(name, {
          ...value,
          line1: description,
          city,
          zip,
          state,
          geofencing,
          country,
        })
      })
    },
    [getDetails, name, setFieldValue, value],
  )

  const selectFirstOption = useCallback(() => {
    if (optionsFetching) {
      return setSelectFirstOptionDelayed(true)
    }
    setSelectFirstOptionDelayed(false)
    if (line1Options.length) {
      handleOptionSelect(null, line1Options[0])
    }
  }, [handleOptionSelect, line1Options, optionsFetching])

  const getPlacePredictions = React.useMemo(
    () =>
      _.throttle((request: { input: string }, callback: (input: GoogleAutoCompleteMixedResponse[]) => void) => {
        setOptionsFetching(true)
        let localPredictionAndPlaceResults: PredictionAndPlaceResults = {}
        const callBackHandler: (input: PredictionAndPlaceResults) => void = input => {
          localPredictionAndPlaceResults = {
            ...localPredictionAndPlaceResults,
            ...input,
          }
          if (
            typeof localPredictionAndPlaceResults.predictionResults !== 'undefined' &&
            typeof localPredictionAndPlaceResults.placeResults !== 'undefined'
          ) {
            callback([
              ...(localPredictionAndPlaceResults.placeResults || []),
              ...(localPredictionAndPlaceResults.predictionResults || []),
            ])
            setOptionsFetching(false)
            if (selectFirstOptionDelayed) selectFirstOption()
            localPredictionAndPlaceResults = {}
          }
        }
        if (googleService.autoComplete) {
          googleService.autoComplete.getPlacePredictions(
            { ...request, types: ['address'] },
            (result: google.maps.places.AutocompletePrediction[]) => {
              callBackHandler({ predictionResults: result })
            },
          )
        }
        if (googleService.places) {
          googleService.places.findPlaceFromQuery(
            { query: request.input, fields: ['name', 'place_id'] },
            (results: google.maps.places.PlaceResult[]) => {
              callBackHandler({ placeResults: results })
            },
          )
        }
      }, 200),
    [selectFirstOption, selectFirstOptionDelayed],
  )

  const onChange = (fieldName: string, fieldValue: string) => {
    !touched[name] && setTouched({ [name]: true })
    setFieldValue(name, {
      ...value,
      [fieldName]: fieldValue,
    })
  }

  const onMapMounted: any = (map: any) => {
    if (_.isEmpty(map)) return
    const mapObject = map.context[MAP]
    googleService.places = new google.maps.places.PlacesService(mapObject)
    googleService.autoComplete = new google.maps.places.AutocompleteService()
  }

  const handleChangeRadius = (newRadius: number) => {
    setFieldValue(name, {
      ...value,
      geofencing: {
        ...geofencing,
        radius: newRadius,
      },
    })
  }

  useEffect(() => {
    let timeoutId: number
    if (loadLocationFromAddress) {
      timeoutId = setTimeout(
        () =>
          getPlacePredictions({ input: value.line1 }, (results: GoogleAutoCompleteMixedResponse[]) => {
            results.length && handleOptionSelect(null, _.first(results) as any)
          }),
        1000,
      )
    }
    return () => clearTimeout(timeoutId)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const classes = useStyles()

  return (
    <Grid container direction={vertical ? 'column-reverse' : 'row'} className={cn({ [classes.verticalGap]: vertical })}>
      {/*
        We need map reference to have working search.
        So we hide block instead of conditional rendering
      */}
      <Grid item sm={vertical ? 12 : 6} style={{ display: hideMap ? 'none' : 'block' }}>
        <Box pr={vertical ? 0 : 2}>
          <ReactGoogleMap
            coordinates={_.get(value, 'geofencing.location.coordinates')}
            geofenceRadius={geofencing.radius}
            onMounted={onMapMounted}
          />
          {showRadius && (
            <GeofenceRadiusSlider
              radius={geofencing.radius}
              min={MIN_RADIUS_IN_METER}
              max={MAX_RADIUS_IN_METER}
              onChange={handleChangeRadius}
            />
          )}
        </Box>
      </Grid>
      <Grid container item sm={hideMap || vertical ? 12 : 6}>
        <Grid item sm={12} xs={12}>
          {fieldTitle && <h3 className="location-form__title">{fieldTitle}</h3>}
          <Autocomplete
            value={_.get(value, 'line1')}
            // @ts-ignore
            onChange={handleOptionSelect}
            onInputChange={(e, v) => {
              onChange('line1', v)
              if (_.isEmpty(v)) {
                setLine1Options([])
                return
              }
              getPlacePredictions({ input: v }, (results: GoogleAutoCompleteMixedResponse[]) => {
                setLine1Options(results || [])
              })
            }}
            options={line1Options}
            getOptionLabel={option => {
              if (typeof option === 'string') {
                return option
              } else {
                // @ts-ignore
                return option.description || option.name
              }
            }}
            renderInput={params => {
              const e = _.get(addressError, 'line1')
              return (
                <MuiTextField
                  {...params}
                  label={label}
                  variant="outlined"
                  error={!!e}
                  helperText={e}
                  placeholder={placeholder}
                  fullWidth
                  // This is a hack to disable chrome auto-fill:
                  // https://stackoverflow.com/questions/15738259/disabling-chrome-autofill
                  inputProps={{ ...params.inputProps, autoComplete: 'new-password' }}
                  InputProps={
                    smaller
                      ? {
                          ...params.InputProps,
                          classes: {
                            root: classes.smaller,
                            input: classes.smallerInput,
                            focused: classes.smallerFocused,
                          },
                        }
                      : params.InputProps
                  }
                  onKeyPress={(event: KeyboardEvent<HTMLDivElement>) => {
                    event.stopPropagation()
                    if (event.key === 'Enter') {
                      setEnterPressed(true)
                      selectFirstOption()
                      // @ts-ignore
                      event.target.blur()
                    }
                  }}
                  onBlur={() => {
                    if (enterPressed) {
                      setEnterPressed(false)
                    } else {
                      selectFirstOption()
                    }
                  }}
                />
              )
            }}
            renderOption={PlacePredictionOption}
            freeSolo={true}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

const parsePlace = (place: google.maps.places.PlaceResult, radius?: number): Address => {
  const addressComponent = new Map<string, string>()
  _.each(place.address_components, (c: google.maps.GeocoderAddressComponent) => {
    addressComponent.set(c.types[0], c.long_name)
  })
  return {
    city:
      addressComponent.get('locality') ||
      addressComponent.get('sublocality') ||
      addressComponent.get('sublocality_level_1') ||
      '',
    zip: addressComponent.get('postal_code') || '',
    state: addressComponent.get('administrative_area_level_1') || '',
    country: addressComponent.get('country') || '',
    county: addressComponent.get('administrative_area_level_2') || '',
    geofencing: {
      ...(!place.geometry
        ? defaultAddress().geofencing
        : {
            location: {
              type: 'Point',
              coordinates: place.geometry.location.toJSON(),
            },
          }),
      ...(radius ? { radius } : {}),
    },
    utcOffset: place.utc_offset || 0,
  } as Address
}
