import { useCallback } from 'react'
import { useCurrentOrg } from '../../auth/hooks'
import { Status } from './fetchRefactoredSchema'
import useFetch from './useFetchRefactored'
import omitBy from 'lodash/omitBy'
import isNil from 'lodash/isNil'

export interface PersonSuggestion {
  id: number
  givenName: string
  familyName: string
  contact: string
  title: string
  groupId: number
  pubnubId: string
}

export interface TimedResponse {
  suggestions: PersonSuggestion[]
  time: number
}

export type FetchHookResult<T, S> = [(search?: string) => Promise<T>, Status<S>]

const useFetchPersonSuggestions = ({
  groupId,
  orderDesc = false,
  limit,
  includeDeleted = false,
}: {
  groupId?: number
  orderDesc?: boolean
  limit?: number
  includeDeleted?: boolean
}): FetchHookResult<TimedResponse, PersonSuggestion[]> => {
  const currentOrg = useCurrentOrg()
  const [request, status] = useFetch<PersonSuggestion[]>()

  const getPersonSuggestions = useCallback(
    (search: string = '') => {
      const time = performance.now()
      return request({
        method: 'get',
        url: groupId
          ? `/organizations/${currentOrg.id}/groups/${groupId}/person-suggestions`
          : `/organizations/${currentOrg.id}/person-suggestions`,
        config: {
          queryStringParameters: omitBy(
            {
              search,
              orderDesc,
              limit,
              includeDeleted,
            },
            isNil,
          ),
        },
      }).then(suggestions => ({
        suggestions: suggestions.map(({ id, ...suggestion }) => ({ id: parseInt(id as any as string), ...suggestion })),
        time,
      }))
    },
    [currentOrg.id, groupId, includeDeleted, limit, orderDesc, request],
  )

  return [getPersonSuggestions, status]
}

export default useFetchPersonSuggestions
