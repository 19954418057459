import React, { useEffect } from 'react'
import { useRouteMatch } from 'react-router'
import { useGetApplicationGroup } from '../hooks'
import Loading from '../../civic-champs-shared/core/Loading'
import GroupApplicationWizardForm from './valid/GroupApplicationWizardForm'

export default function Applications(props) {
  const { currentUser } = props
  const { params } = useRouteMatch()
  const { orgSlugName, groupId } = params

  const [getApplicationGroup, { loading, result, error }] = useGetApplicationGroup()
  const { group, organization } = result || {}
  useEffect(() => {
    getApplicationGroup(orgSlugName, groupId)
  }, [getApplicationGroup, groupId, orgSlugName])

  if (error) {
    return null
  }

  return loading ? (
    <Loading size={60} />
  ) : (
    <GroupApplicationWizardForm
      group={group}
      organization={organization}
      orgSlugName={orgSlugName}
      currentUser={currentUser}
    />
  )
}
