import React, { useCallback, useEffect, useRef, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import { FieldProps } from 'formik'
import isNull from 'lodash/isNull'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

import useFetchPersonSuggestions, { PersonSuggestion } from '../../api/hooks/useFetchPersonSuggestions'
import useAddPersonToGroup from './hooks/useAddPersonToGroup'
import format from '../../utils/format'
import { useHandleBlur } from '../../helpers/useHandleBlur'
import { useAutocompleteStyles } from '../hooks/useAutocompleteStyles'
import { usePaperComponentWithOnCreate } from '../hooks/usePaperComponentWithOnCreate'

const formatContact = (contact: string) => (/^\+\d{11}$/.test(contact) ? format.phoneNumber(contact) : contact)

export const getBasicTextFieldStyles = (legendWidthFixture?: number) => ({
  '& label': {
    fontFamily: 'Nunito, sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '18px',
    letterSpacing: '0.5px',
  },
  '& label:not(.Mui-focused):not(.MuiFormLabel-filled)': {
    top: '-6px',
  },
  '& label.Mui-focused': {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  '& label.MuiFormLabel-filled': {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  '& .MuiOutlinedInput-root': {
    fontSize: '16px',
    lineHeight: '20px',
    minHeight: '45px',
    padding: '2px 62px 2px 8px',
    '& fieldset': {
      borderColor: 'rgb(175, 188, 213)',
    },
    '&:hover fieldset': {
      borderColor: 'rgb(175, 188, 213)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.87)',
      border: '1px solid',
    },
  },
  ...(legendWidthFixture
    ? {
        '& label.MuiInputLabel-shrink ~ .MuiOutlinedInput-root fieldset.MuiOutlinedInput-notchedOutline legend': {
          width: `${legendWidthFixture}px !important`,
        },
      }
    : {}),
})

export interface PersonAutocompleteProps {
  label: string
  groupId: number
  groupTitle: string
  otherTitle?: string
  onCreate?: (inputValue: string, groupId: number) => void
  onGroupAdd?: (personId: number, groupId: number) => void
  onBlur?: (...values: any) => void
  onChange: (person: PersonSuggestion) => void
  error?: string
  hasError?: boolean
  required?: boolean
  value?: PersonSuggestion | null
  disableGroupPersons?: boolean
  addToGroupOnSelection?: boolean
  legendWidthFixture?: number
  disabled?: boolean
  addNewPersonText?: string
}

export default function PersonAutocomplete(props: PersonAutocompleteProps) {
  const {
    label,
    groupId,
    groupTitle,
    otherTitle = 'Other Members',
    onCreate,
    onChange,
    onBlur,
    error,
    value,
    hasError = false,
    required = false,
    disableGroupPersons = false,
    addToGroupOnSelection = true,
    legendWidthFixture,
    disabled = false,
    addNewPersonText = `+ Add a new person`,
  } = props

  const focusEl = useRef<HTMLDivElement | null>(null)
  const [inputFocused, setInputFocused] = useState(false)
  const getOptionLabel = useCallback(
    (option: PersonSuggestion) =>
      option.id ? `${option.givenName} ${option.familyName} - ${formatContact(option.contact)}` : addNewPersonText,
    [addNewPersonText],
  )

  const getSearchLabel = useCallback(
    (option: PersonSuggestion) =>
      option.id ? `${option.givenName} ${option.familyName} - ${option.contact}` : addNewPersonText,
    [addNewPersonText],
  )

  const filterSuggestions = useCallback(createFilterOptions({ stringify: getSearchLabel }), [
    createFilterOptions,
    getSearchLabel,
  ])

  const [suggestions, setSuggestions] = useState<PersonSuggestion[]>([])
  const [inputValue, setInputValue] = useState('')
  const handleCreate = useCallback(() => {
    onCreate && onCreate(inputValue, groupId)
  }, [groupId, inputValue, onCreate])
  const [open, setOpen] = useState(false)
  const PaperComponent = usePaperComponentWithOnCreate({
    addNewText: addNewPersonText,
    onCreate: onCreate ? handleCreate : undefined,
    ref: focusEl,
  })
  const [addPersonToGroup] = useAddPersonToGroup(groupId)
  const defaultGroupAdd = (personId: number) => {
    addPersonToGroup({
      type: 'EXISTING_USER',
      userId: personId,
    })
  }
  const { onGroupAdd = defaultGroupAdd } = props
  const handleBlur = useHandleBlur([focusEl], () => {
    setOpen(false)
    setInputFocused(false)
    onBlur && onBlur()
  })
  // @ts-ignore
  const [fetchSuggestions, { loading }] = useFetchPersonSuggestions({ groupId, limit: 0 })
  const classes = useAutocompleteStyles({ legendWidthFixture })

  useEffect(() => {
    // @ts-ignore
    fetchSuggestions().then(({ suggestions }) => setSuggestions(suggestions))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Autocomplete
      options={suggestions}
      value={value}
      disabled={disabled}
      loading={loading}
      classes={{ groupLabel: classes.groupLabel, option: classes.option, listbox: classes.listbox }}
      getOptionDisabled={({ groupId, id }) => !!(disableGroupPersons && id && groupId)}
      onChange={(_event, newValue) => {
        addToGroupOnSelection &&
          onGroupAdd &&
          !isNull(newValue) &&
          isNull(newValue.groupId) &&
          onGroupAdd(newValue.id, newValue.groupId)
        if (!isNull(newValue)) {
          onChange(newValue)
        }
      }}
      onInputChange={(event, newValue) => {
        setInputValue(newValue)
        inputFocused && setOpen(true)
      }}
      onFocus={() => {
        setOpen(true)
        setInputFocused(true)
      }}
      onBlur={handleBlur}
      // not a debug code, needed to properly handle add volunteer click
      debug={true}
      // @ts-ignore
      filterOptions={filterSuggestions}
      blurOnSelect
      open={open}
      PaperComponent={PaperComponent}
      groupBy={option => (option.groupId ? groupTitle : otherTitle)}
      getOptionLabel={getOptionLabel}
      popupIcon={<KeyboardArrowDownIcon onClick={() => setOpen(v => !v)} />}
      className={classes.autoComplete}
      renderInput={params => (
        <TextField
          {...params}
          fullWidth
          value={inputValue}
          classes={{ root: classes.textField }}
          error={hasError}
          helperText={hasError && error}
          required={required}
          label={label}
          variant="outlined"
        />
      )}
    />
  )
}

interface FormikPersonAutocompleteProps
  extends Omit<PersonAutocompleteProps, 'onChange' | 'hasError' | 'error' | 'value' | 'onBlur'>,
    FieldProps {}

export const FormikPersonAutocomplete = ({
  field: { name, value },
  form: { touched, errors, setFieldValue, setFieldTouched },
  meta,
  ...props
}: FormikPersonAutocompleteProps) => (
  <PersonAutocomplete
    {...props}
    onChange={value => setFieldValue(name, value)}
    hasError={touched[name] && isNull(value)}
    error={errors[name] as string}
    value={value}
    onBlur={() => requestAnimationFrame(() => setFieldTouched(name, true))}
  />
)
