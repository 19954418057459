import { merge } from 'lodash'
import React, { useRef, useState } from 'react'
import { FormHelperText } from '@material-ui/core'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'
import './CkEditorField.css'

const useStyles = makeStyles({
  label: {
    fontSize: '16px',
    fontFamily: 'Nunito, sans-serif',
    fontWeight: 400,
    lineHeight: '18px',
    letterSpacing: '0.5px',
    transition: 'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    top: 0,
    left: 0,
    position: 'absolute',
    transform: 'translate(11px, 51px) scale(1)',
    zIndex: 1,
    color: 'rgba(0, 0, 0, 0.54)',
    '&$focused': {
      transform: 'translate(2px, 29px) scale(0.75)',
      color: 'rgba(0, 0, 0, 0.87)',
      padding: '0 4px',
      backgroundColor: '#fff',
    },
  },
  focused: {},
})

export default function CKEditorField(props) {
  const classes = useStyles()
  const [focused, setFocused] = useState(false)
  const { field, form, config, label, ...other } = props
  const error = form.errors[field.name] //TODO no "meta" is passed down for "backwards compatibility"

  //TODO something in react/formik is undoing the default character transformations
  //     and causing infinite update loops.  Removing those transformations...
  const finalConfig = merge(config, {
    typing: {
      transformations: {
        remove: ['ellipsis', 'enDash', 'emDash'],
      },
    },
  })

  const editorRef = useRef()
  const contentRef = useRef(field.value)

  const handleInit = editor => (editorRef.current = editor)
  const handleChange = (event, editor) => {
    const newContent = editor.getData()
    if (contentRef.current !== newContent) {
      contentRef.current = newContent
      form.setFieldValue(field.name, newContent, true)
    }
  }

  const handleBlur = () => {
    setFocused(0)
    form.setFieldTouched(field.name, true)
  }

  const handleFocus = () => {
    setFocused(1)
  }

  return (
    <>
      {label && <div className={cn(classes.label, { [classes.focused]: focused || field.value })}>{label}</div>}
      <CKEditor
        {...other}
        config={finalConfig}
        data={field.value}
        onReady={handleInit}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
      {error && <FormHelperText error={true}>{error}</FormHelperText>}
    </>
  )
}
