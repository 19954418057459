import React, { useMemo } from 'react'
import { get } from 'lodash'
import { Grid, Button } from '@material-ui/core'
import { isAndroid, isIOS } from 'react-device-detect'
import { InvitationType } from '../../../civic-champs-shared/question-sets/types'
import { DesktopQRCodes } from './DesktopQRCodes'
import { LinkButton } from '../../../common/components/LinkButton'

export default function Welcome(props) {
  const { children, invite } = props
  const isHelpingHands =
    invite &&
    [InvitationType.TaskRequester, InvitationType.TaskVolunteer, InvitationType.TaskRecipient].includes(
      invite.invitationType,
    )
  const baseOrgWebsite = invite && get(invite, 'organization.website')
  const finalOrgWebsite =
    baseOrgWebsite && baseOrgWebsite.indexOf('://') === -1 ? 'http://' + baseOrgWebsite : baseOrgWebsite
  const helpingHandsLink = process.env.REACT_APP_VOLUNTEER_SITE || 'https://volunteer.civicchamps.com'

  const linkComponent = useMemo(() => {
    if (isHelpingHands) {
      return <LinkButton link={helpingHandsLink} label="Get Started" />
    } else if (isIOS) {
      return (
        <LinkButton
          link="https://apps.apple.com/us/app/civic-champs/id1460594845"
          description="Get the app by following the link below."
        />
      )
    } else if (isAndroid) {
      return (
        <LinkButton
          link="https://play.google.com/store/apps/details?id=com.civicchamps&hl=en_US"
          description="Get the app by following the link below."
        />
      )
    } else {
      return <DesktopQRCodes />
    }
  }, [isHelpingHands])

  return (
    <>
      {children}
      <Grid container direction="column" justify="center" alignItems="center">
        {linkComponent}
        {finalOrgWebsite && !isHelpingHands && (
          <Button className="submit button" onClick={() => (window.location.href = finalOrgWebsite)}>
            Got It!
          </Button>
        )}
      </Grid>
    </>
  )
}
